<ng-container *ngIf="localeContent$ | async as locale">
  <div id="web-app" style="background: var(--color-background); height: 100vh; overflow-y: auto">
    <div class="card-pf">
      <header style="margin-bottom: 20px">
        <div>
          <div *ngIf="companyLogo">
            <div
              style="display: flex; justify-content: center"
              [style.padding-top]="isMobile ? '3rem' : '5rem'"
            >
              <div class="Container" [innerHTML]="companyLogo | safeHtml"></div>
            </div>
          </div>
          <div *ngIf="locale.loginEnterPinTitle && locale.loginEnterPinTitle.length > 0">
            <div>
              <div>
                <div style="display: flex; justify-content: space-evenly">
                  <div style="align-self: center">
                    <h1>{{ locale.loginEnterPinTitle }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <!-- Error Display -->
      <div *ngIf="error" class="error-message">
        {{ error }}
      </div>

      <!-- Desktop PIN Boxes -->
      <ng-container *ngIf="!isMobile">
        <finxone-web-frontend-pin-boxes
          [(pin)]="pin"
          [error]="error"
          (pinBoxes)="checkPinComplete()"
        ></finxone-web-frontend-pin-boxes>
      </ng-container>

      <!-- Mobile PIN Pad -->
      <ng-container *ngIf="isMobile">
        <finxone-web-frontend-pin-pad
          [(pin)]="pin"
          [error]="error"
          [loginEnterPinSubTitle]="locale.loginEnterPinSubTitle"
          (pinPad)="checkPinComplete()"
        ></finxone-web-frontend-pin-pad>
      </ng-container>

      <div class="user-info">
        <span class="alt-flow" (click)="onAltFlowClick($event)">{{ locale.doTryAnotherWay }}</span>
      </div>

      <div class="" style="width: 100%; display: flex">
        <input
          style="width: 100%"
          class="bottom-back button primaryButton full-width w-100 body1"
          type="button"
          value="Back"
          (click)="onBackClick($event)"
        />
      </div>
    </div>
  </div>
</ng-container>
