import {
  ClassProvider,
  ConstructorProvider,
  ExistingProvider,
  FactoryProvider,
  Injector,
  StaticClassProvider,
  TypeProvider,
  ValueProvider,
} from '@angular/core';
import {
  APP_ZONES,
  ContainerType,
  NewUiZoneWidgetConfig,
  ProfileStateModel,
  ProviderClass,
  SupportedDevice,
  UiPageConfig,
  UiZoneWidgetConfig,
  UserInfoType,
} from '@finxone-platform/shared/sys-config-types';
import Handlebars from 'handlebars';

import { ActivatedRoute, ParamMap } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable, combineLatest, map, take } from 'rxjs';
import { GetOrganisation } from '../../lib/actions/organisation.action';
import { SetActiveOrgId } from '../../lib/actions/user-profile.action';
import { ConfigService } from '../../lib/services/config-service/config-service.service';
import { CountriesCurrenciesService } from '../../lib/services/countries-currencies-service/countries-currencies.service';
import { isAuthenticatedRoute } from '../../lib/utils/auth-checks.utils';
import {
  setOverFlowAutoOnBodyTag,
  setOverFlowHiddenOnBodyTag,
} from '../../lib/utils/scrolling-utils/scrolling.utils';
import { buildHandleBarModel } from '../../lib/utils/template/model-builder.utils';
import { formatNameAsUrl } from '../../lib/utils/zone-url.utils';
import { PageConfigurationProvider } from './page-configuration.provider';
import { ZonePageUrlProvider } from './zone-url-provider';

export function createInjector(
  widget: UiZoneWidgetConfig,
  page: UiPageConfig,
  injector: Injector,
  configService: ConfigService,
  zonePageUrlProvider: ZonePageUrlProvider,
  user: UserInfoType,
  userProfile?: ProfileStateModel,
  // Try remove as this is used for page states which we don't really use anymore
  widgetSection?: {
    widgetName: string;
    widgetIndex?: number;
    isSectionPopup?: boolean;
  },
  extraProviders?: (
    | ValueProvider
    | ExistingProvider
    | StaticClassProvider
    | ConstructorProvider
    | FactoryProvider
    | TypeProvider
    | ClassProvider
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
  )[],
): Injector {
  if (!page.pageConfiguration || Object.keys(page.pageConfiguration).length === 0) {
    console.error('[DynamicZoneHolder] Page Configuration is empty at Injection.');
  }

  const compAttri = widget.attributes;
  const updatedCompAttri = widgetSection
    ? {
        widgetName: widgetSection.widgetName,
        widgetIndex: widgetSection.widgetIndex,
        isSectionPopup: widgetSection.isSectionPopup,
        ...compAttri,
      }
    : {
        ...compAttri,
      };

  return Injector.create({
    providers: [
      {
        provide: ProviderClass,
        useValue: {
          attri: updatedCompAttri,
          uniqueId: widget?.uniqueId,
          widgetLayout: widget,
          widgetConfig: widget,
          id: user ? user.id : '',
          userProfileData: user ? user : {},
          userProfile: userProfile ?? {},
        },
      },
      {
        provide: ZonePageUrlProvider,
        useValue: zonePageUrlProvider,
      },
      {
        provide: ConfigService,
        useValue: configService,
      },
      {
        provide: PageConfigurationProvider,
        useValue: page.pageConfiguration,
      },
    ].concat(extraProviders ?? []),
    parent: injector,
  });
}

export function createChildWidgetInjector(
  widget: NewUiZoneWidgetConfig,
  injector: Injector,
  dataIn: ProviderClass<object>,
  extraProviders?: (
    | ValueProvider
    | ExistingProvider
    | StaticClassProvider
    | ConstructorProvider
    | FactoryProvider
    | TypeProvider
    | ClassProvider
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
  )[],
) {
  return Injector.create({
    providers: [
      {
        provide: ProviderClass,
        useValue: {
          attri: widget.attributes,
          uniqueId: widget?.uniqueId,
          widgetLayout: widget,
          widgetConfig: widget,
          id: dataIn.id,
          userProfileData: dataIn.userProfileData,
          userProfile: dataIn.userProfile,
        },
      },
    ].concat(extraProviders ?? []),
    parent: injector,
  });
}

export function resetStylesOnWebApp() {
  const element = document.getElementById('web-app');
  if (element) {
    element.style.removeProperty('background');
    element.style.removeProperty('backgroundSize');
    element.style.removeProperty('opacity');
    element.style.removeProperty('background');
    element.style.backgroundColor = 'var(--color-background)';

    const cssOverride = window.document.getElementById('page-css-override');
    if (cssOverride) {
      cssOverride.parentNode?.removeChild(cssOverride);
    }
  }
}

export function getRouteParams(route: ActivatedRoute): Observable<{
  zoneUrlInput: string;
  pageUrlInput: string;
  roleInput: string;
  subscriptionInput: string;
  idInput: string;
}> {
  return route.paramMap.pipe(
    map((params: ParamMap) => {
      const pageOrModal = (params.get('modal-page') ? params.get('modal-page') : params.get('page')) ?? '';
      if (params.get('zone') === null || pageOrModal === null) {
        throw Error('No zone or page specified via path or input');
      } else {
        return {
          zoneUrlInput: params.get('zone') ?? '',
          pageUrlInput: pageOrModal ?? '',
          roleInput: params.get('role') ?? '',
          subscriptionInput: params.get('subscription') ?? '',
          idInput: params.get('id') ?? '',
        };
      }
    }),
  );
}

export function fetchProfileAndSetOrgInfoBasedOnZone(
  profile$: Observable<ProfileStateModel>,
  store: Store,
  zone: string,
) {
  return profile$.pipe(
    take(2),
    map((profileData) => {
      let count = 0;
      if (profileData?.activeOrganisationId && isAuthenticatedRoute(window.location.pathname)) {
        store.dispatch(new SetActiveOrgId(profileData.activeOrganisationId ?? ''));
      }
      if (zone === formatNameAsUrl(APP_ZONES.ORGANISATION_VERIFICATION)) {
        const organisationId = profileData?.activeOrganisationId ?? '';
        if (organisationId && count === 0) {
          store.dispatch(new GetOrganisation(organisationId));
          count++;
        }
      }

      return profileData ? profileData : undefined;
    }),
  );
}

const originalWidgetLayouts = new WeakMap<object, UiZoneWidgetConfig[]>();

export function hideWidgetsIfNeededWithCallback(
  widgetLayout: UiZoneWidgetConfig[],
  store: Store,
  countriesCurrenciesService: CountriesCurrenciesService,
  callback: () => void,
) {
  // Get or store original layout
  if (!originalWidgetLayouts.has(widgetLayout)) {
    originalWidgetLayouts.set(widgetLayout, [...widgetLayout]);
  }
  const originalLayout = originalWidgetLayouts.get(widgetLayout)!;

  const widgetsToHide = originalLayout.filter((w) => w.attributes.hideCondition);

  if (!widgetsToHide.length) {
    callback();
    return;
  }

  combineLatest(
    widgetsToHide.map((w) =>
      evaluateCondition(w.attributes.hideCondition as string, store, countriesCurrenciesService),
    ),
  ).subscribe((results) => {
    const hiddenWidgetIds = new Set<string | number>();

    widgetsToHide.forEach((w, index) => {
      try {
        const result = results[index];
        const booleanResult = /true/.test(result);

        if (booleanResult) {
          console.log(`hiding widget: ${w.uniqueId} with condition: ${w.attributes.hideCondition}`);
          hiddenWidgetIds.add(w.uniqueId);
        }
      } catch (error) {
        console.error(`Failed to evaluate hide condition on widget: ${w.uniqueId} with error: ${error}`);
      }
    });

    const filteredLayout = originalLayout.filter((widget) => !hiddenWidgetIds.has(widget.uniqueId));

    widgetLayout.length = 0;
    widgetLayout.push(...filteredLayout);

    callback();
  });
}

export function isBodyScrollingEnabled(device: SupportedDevice, pageConfig: UiPageConfig): void {
  const isDesktop = device === 'desktop';
  const isBodyScrollingEnabled = pageConfig.pageConfiguration?.isBodyScrollingEnabled;

  if ((isDesktop && isBodyScrollingEnabled === false) || (!isDesktop && isBodyScrollingEnabled !== true)) {
    setOverFlowHiddenOnBodyTag();
  } else {
    setOverFlowAutoOnBodyTag();
  }
}

export function applyPageConfigStyles(element: HTMLElement, pageConfig: UiPageConfig, isModalPage = false) {
  if (!isModalPage && pageConfig?.containerType === ContainerType.PAGE) {
    element.style.backgroundColor =
      pageConfig.pageConfiguration?.backgroundColor ?? 'var(--color-background)';
  }
  if (!pageConfig.pageConfiguration?.backgroundImage?.image) {
    const prevImageElement = document.getElementById('backgroundImageWrapper');
    if (prevImageElement) {
      prevImageElement.remove();
    }
  }

  if (pageConfig?.pageConfiguration?.backgroundImage?.image) {
    createBackgroundImageWrapperElement(element, pageConfig);
  }

  if (
    pageConfig?.pageConfiguration?.backgroundGradient &&
    pageConfig?.pageConfiguration?.backgroundGradient?.length > 0
  ) {
    element.style.background = pageConfig?.pageConfiguration?.backgroundGradient ?? 'unset';
    element.style.height = pageConfig?.pageConfiguration?.backgroundGradient ? '100vh' : 'unset';
  }
  if (pageConfig.pageConfiguration?.fontsConfig) {
    const sheet = window.document.createElement('style');

    sheet.innerHTML = buildCssFontRules(pageConfig.pageConfiguration?.fontsConfig);
    sheet.title = 'page-css-override';
    sheet.id = 'page-css-override';
  }
}

export function createBackgroundImageWrapperElement(element: HTMLElement, pageConfig: UiPageConfig) {
  const prevImageElement = document.getElementById('backgroundImageWrapper');
  if (prevImageElement) {
    prevImageElement.remove();
  }
  if (!document.getElementById('backgroundImageWrapper')) {
    const pseudoElement = document.createElement('div');
    pseudoElement.id = 'backgroundImageWrapper';
    pseudoElement.style.content = '""';
    pseudoElement.style.position = 'absolute';
    pseudoElement.style.top = '0';
    pseudoElement.style.left = '0';
    pseudoElement.style.height = element.clientHeight ? element.clientHeight.toString() + 'px' : '100%';
    pseudoElement.style.width = '100%';
    pseudoElement.style.backgroundImage = pageConfig?.pageConfiguration?.backgroundImage?.image ?? 'unset';
    pseudoElement.style.backgroundColor = 'transparent';
    pseudoElement.style.opacity =
      pageConfig?.pageConfiguration?.backgroundImage?.opacity.toString() ?? 'unset';
    pseudoElement.style.backgroundSize = pageConfig?.pageConfiguration?.backgroundSize ?? 'auto';
    pseudoElement.style.backgroundAttachment = 'fixed';
    pseudoElement.style.backgroundPosition = 'center';
    pseudoElement.style.zIndex = '-1';
    if (pageConfig?.pageConfiguration?.backgroundPosition) {
      pseudoElement.style.backgroundPositionX =
        pageConfig?.pageConfiguration?.backgroundPosition.x ?? 'unset';
      pseudoElement.style.backgroundPositionY =
        pageConfig?.pageConfiguration?.backgroundPosition.y ?? 'unset';
    }
    element.appendChild(pseudoElement);
  }
}

export function buildCssFontRules(fontsConfig: {
  h1: {
    'font-family': string;
    'font-weight': string;
  };
  h2: {
    'font-family': string;
    'font-weight': string;
  };

  h3: {
    'font-family': string;
    'font-weight': string;
  };
  bodyFont: {
    'font-family': string;
    'font-weight': string;
  };
}): string {
  return `
    #web-app h1 { ${
      fontsConfig?.h1['font-weight'] ? `font-weight: ${fontsConfig?.h1['font-weight']}` : ''
    }; ${fontsConfig?.h1['font-family'] ? `font-family: ${fontsConfig?.h1['font-family']}` : ''} }
    #web-app h2 { ${
      fontsConfig?.h2['font-weight'] ? `font-weight: ${fontsConfig?.h2['font-weight']}` : ''
    }; ${fontsConfig?.h2['font-family'] ? `font-family: ${fontsConfig?.h2['font-family']}` : ''} }
    #web-app h3 { ${
      fontsConfig?.h3['font-weight'] ? `font-weight: ${fontsConfig?.h3['font-weight']}` : ''
    }; ${fontsConfig?.h3['font-family'] ? `font-family: ${fontsConfig?.h3['font-family']}` : ''} }
    #web-app body { ${
      fontsConfig?.bodyFont['font-weight'] ? `font-weight: ${fontsConfig?.bodyFont['font-weight']}` : ''
    }; ${fontsConfig?.bodyFont['font-family'] ? `font-family: ${fontsConfig?.bodyFont['font-family']}` : ''} }
  `;
}

export const urlSearchParamsToJson = (searchParams: URLSearchParams): Record<string, string> => {
  const params: Record<string, string> = {};

  // Iterate through all the search parameters
  searchParams?.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export function evaluateCondition(
  condition: string,
  store: Store,
  countriesCurrenciesService: CountriesCurrenciesService,
) {
  const model$ = buildHandleBarModel(store, '', countriesCurrenciesService);
  return model$
    .pipe(take(1))
    .toPromise()
    .then((model) => {
      const templateFunction = Handlebars.compile(`{{#if (${condition})}}true{{else}}false{{/if}}`);
      return templateFunction(model);
    });
}
