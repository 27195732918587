import { signal } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { formatNameAsUrl } from '../../zone-url.utils';

export const showEPINUtil = (router: Router) => {
  const targetUrl = `/zones/${formatNameAsUrl(APP_ZONES.CARD_MANAGEMENT_ZONE)}/show-pin`;

  if (getBiometricStatus()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flutterWebView = (window as any).flutter_inappwebview;
    flutterWebView.callHandler('biometricsLogin', []).then(() => {
      router.navigateByUrl(targetUrl);
    });
  } else {
    router.navigate(['confirmation-code'], {
      queryParams: {
        claimType: 'getEpin',
        confirmationType: 'PIN',
        returnUrl: targetUrl,
      },
    });
  }
};

const getBiometricStatus = (): boolean => {
  // Initialize the biometric status signal
  const isBiometricEnabled = signal(false);

  // Retrieve stored preferences
  const appPreferences = localStorage.getItem('APP_PREFERENCES');
  if (appPreferences) {
    const { isBiometricEnabled: isEnabled } = JSON.parse(appPreferences) || {};
    isBiometricEnabled.set(!!isEnabled); // Ensure a boolean value is set
    return isBiometricEnabled();
  }

  // Update the signal when the biometrics preference state changes
  const handleBiometricStateChange = (event: Event) => {
    const customEvent = event as CustomEvent;
    isBiometricEnabled.set(!!customEvent.detail); // Ensure a boolean value is set
  };

  // trigger once and then automatically remove after the first time it is invoked.
  window.addEventListener('biometricsPreferenceState', handleBiometricStateChange, { once: true });

  return isBiometricEnabled();
};
