import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardManagementService } from '@app/finxone-web-frontend/app/lib/services/card-management-service/card-management-service';
import { shareCvvDetails } from '@app/finxone-web-frontend/app/lib/utils/cta-button-actions/card-management-v2/secure-cvv-details.utils';
import { BulkPaymentMasterStatus, FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  BaseWidgetProperties,
  ButtonActionType,
  DocumentType,
  KycStatus,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ResetFormDataAction } from '../../actions/form-submission.action';
import { AccountService } from '../../services/account-service/account-service.service';
import { BackOverrideService } from '../../services/back-override-service/back-override.service';
import { BulkPaymentService } from '../../services/bulk-payment-service/bulk-payment-service.service';
import { ConfigService } from '../../services/config-service/config-service.service';
import { CtaButtonSignalService } from '../../services/cta-button-signal-service/cta-button-signal.service';
import { FeeManagementService } from '../../services/fee-management-service/fee-management.service';
import { FormSubmissionService } from '../../services/form-submission-service/form-submission-service.service';
import { GraphqlServiceService } from '../../services/graphql-service/graphql-service.service';
import { KeycloakWrapperService } from '../../services/keycloak-wrapper-service/keycloak-wrapper.service';
import { MetadataService } from '../../services/metadata-service/metadata-service.service';
import { RevenirService } from '../../services/revenir-service/revenir.service';
import { BulkPaymentListState } from '../../state/bulk-payment.state';
import { FeeManagementState } from '../../state/fee-management.state';
import { FormActionState } from '../../state/form-submision.state';
import { ProjectSettingsState } from '../../state/project-settings.state';
import { ProfileState } from '../../state/user-profile.state';
import { formatNameAsUrl } from '../zone-url.utils';
import { WorkflowFormsService } from './../../services/workflow-forms/workflow-forms.service';
import { addEmployeeMethod, inviteEmployeeMethod } from './add-employee/add-employee-action.utils';
import { editBeneficiary } from './beneficiary-zone/edit-beneficiary.utils';
import { setBeneficiaryDataAndRedirectToEditBeneficiaryPage } from './beneficiary-zone/redirect-to-edit-beneficiary.utils';
import { callRemoveBeneficiaryAPI } from './beneficiary-zone/remove-beneficiary.utils';
import { callEditIntlBeneficiaryAPI } from './beneficiary-zone/submit-edit-beneficiary-request.utils';
import { addEmployeeToTripAction } from './broker-employee/add-employee-trip';
import {
  emailToGuests,
  goToEditEvent,
  goToInviteGuest,
  inviteGuest,
} from './broker-zones/invite-guest-action.utils';
import { removeGuestFromEvent } from './broker-zones/remove-guest-actions.utils';
import {
  addBeneficiary,
  approveBulkPayment,
  bulkPaymentApproveRejectButtonStatus,
  bulkPaymentApproverRejectButton,
  cancelAllPayment,
  downloadCsvTemplate,
  goToPrePayment,
  makePayment,
  proceedBulkPayment,
  proceedCSVFile,
  refreshBulkPayment,
  rejectAllPayment,
} from './bulk-payments/bulk-payments.utils';
import { onCancelCard, refreshCardsAndRedirect } from './card-management-v2/cancel-card-actions.utils';
import { onSetCardName } from './card-management-v2/set-card-name.utils';
import { showEPINUtil } from './card-management-v2/show-epin.utils';
import { subscribeToAddEditFeeFormStateDataToHandleBtnValidation } from './cta-button-validations/add-edit-fee-cta.utils';
import { addEditBeneficiary } from './domestic-payments/add-beneficiary';
import { checkAuthSmsStatusAndRedirect } from './domestic-payments/check-sms-auth-status-and-redirect';
import { paymentApproverInitiateDomesticPayment } from './domestic-payments/payment-approver-init-payment.utils';
import { checkPaymentRequest } from './domestic-payments/payment-request';
import { paymentRequestApprove } from './domestic-payments/payment-request-approve';
import { paymentRequestProcessing } from './domestic-payments/payment-request-processing';
import { paymentRequestReject } from './domestic-payments/payment-request-reject';
import { updateBeneficiaryFormData } from './domestic-payments/update-beneficiary-form-data';
import { handleAddEditFeeSubmission } from './fee-management-zone/add-edit-fee-action.utils';
import { handleAddEditFeeGroupSubmission } from './fee-management-zone/add-edit-fee-group-action.utils';
import { handleAdHocFeeSubmission } from './fee-management-zone/create-ad-hoc-fee-action.utils';
import { removeFeeAction, removeFeeGroupAction } from './fee-management-zone/remove-action.utils';
import { handleRemoveClientFromFeeGroupRequest } from './fee-management-zone/remove-client-from-fee-group-action.utils';
import { retryFailedFee } from './fee-management-zone/retry-fee-action.utils';
import { revertChargedFee } from './fee-management-zone/revert-fee-action.utils';
import { handleUpdateClientFeeGroupRequest } from './fee-management-zone/update-client-fee-group.utils';
import { callIntlBeneficiaryAPI } from './intl-payments/add-international-beneficiary';
import { checkSourceAccountAndRedirectToPayNewIntlBeneficiary } from './intl-payments/check-source-account-and-redirect-intl-beneficiary';
import { submitInternationPayment } from './intl-payments/submit-international-payment';
import { getOrganisationDataFromStoreAndCallUpdateMethod } from './kyb-zone/kyb-zone-action.utils';
import { checkFormActionDataKycDetailsAndRedirect } from './kyc-zone/kyc-zone-actions.utils';
import { assignPersonaUser } from './manage-users-zone/manage-users-actions.utils';
import { handleBusinessUserRedirectionOnOnboardingFlows } from './onboarding-zone/business-user-onboarding-redirection-action.utils';
import { downloadBankStatement } from './profile-zone/download-bank-statement.utils';
import { submitProfileZoneUserProfileChanges } from './profile-zone/edit-user-profile.utils';
import { saveTransactionAction } from './revenir-zone/add-transaction-utils';
import { handleBarcodeRedirection } from './revenir-zone/barcode-utils';
import {
  addRevenirTrip,
  archiveRevenirTrip,
  checkBarcodeScanDoneRevenirTrip,
  confirmScanArchiveRevenirTrip,
  downloadTaxFreeForms,
  passportDetails,
  reviewBoardingPass,
  skipBoardingPass,
} from './revenir-zone/trip-utils';
import { uploadReviewedTransactionDocument } from './revenir-zone/upload-reviewed-transaction-document-utils';
import { addUserSendInvite } from './user-management-zone-actions/add-user-send-invite.utils';
import { resetAssignPersonaFormStateAndRedirect } from './user-management-zone-actions/assign-persona-flow-actions.utils';
import { mangeUseredit } from './user-management-zone-actions/manage-user-edit.utils';
import { submitVerificationProfileChanges } from './verification-zone/submit-profile-changes-in-verification-flow';

type functionCtaType = (injector: Injector, attri: UiZoneWidgetAttributeConfig) => void;

export const ctaButtonFunctionsMap: Map<ButtonActionType, functionCtaType> = new Map<
  ButtonActionType,
  functionCtaType
>([
  [
    ButtonActionType.KYC_EMAIL_PHONE_VERIFIED_CHECK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkFormActionDataKycDetailsAndRedirect(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.UPDATE_ORGANISATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      getOrganisationDataFromStoreAndCallUpdateMethod(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.INVITE_GUEST,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      inviteGuest(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.GO_TO_INVITE_GUEST,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      goToInviteGuest(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.REMOVE_GUEST_FROM_EVENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      removeGuestFromEvent(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
      );
    },
  ],
  [
    ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      resetAssignPersonaFormStateAndRedirect(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      );
    },
  ],
  [
    ButtonActionType.ADD_EMPLOYEE_CONFIRM_SAVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEmployeeMethod(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
      );
    },
  ],

  [
    ButtonActionType.ADD_EMPLOYEE_INVITE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      inviteEmployeeMethod(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.ASSIGN_PERSONA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      assignPersonaUser(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.ADD_USER_SEND_INVITE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addUserSendInvite(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.PAY_NEW_INTL_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkSourceAccountAndRedirectToPayNewIntlBeneficiary(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.REDIRECT_TO_EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      setBeneficiaryDataAndRedirectToEditBeneficiaryPage(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      callEditIntlBeneficiaryAPI(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.REMOVE_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      callRemoveBeneficiaryAPI(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      editBeneficiary(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      );
    },
  ],
  [
    ButtonActionType.MANAGE_USER_EDIT_AND_SAVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      mangeUseredit(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.ADD_EMPLOYEE_TO_TRIP_CONFIRM,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEmployeeToTripAction(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.EMAIL_ALL_GUESTS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      emailToGuests(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.GO_TO_EDIT_EVENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      goToEditEvent(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadCsvTemplate(injector.get(AlertHandlerService));
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkPaymentRequest(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FormSubmissionService),
        injector.get(ConfigService),
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_PROCESSING,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      paymentRequestProcessing(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_APPROVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      paymentRequestApprove(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_REJECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      paymentRequestReject(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_APPROVER_MAKE_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      paymentApproverInitiateDomesticPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      proceedCSVFile(
        injector.get(Store),
        injector.get(AccountService),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.REFRESH_BULK_PAYMENT_LIST,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      refreshBulkPayment(injector.get(Store));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      goToPrePayment(injector.get(Router));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addBeneficiary(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV_BANK_STATEMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadBankStatement(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        DocumentType.CSV,
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_PDF_BANK_STATEMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadBankStatement(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        DocumentType.PDF,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_MAKE_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      makePayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
        injector.get(AccountService),
        injector.get(FormSubmissionService),
        injector.get(ConfigService),
        injector.get(FeeManagementService),
        injector.get(KeycloakWrapperService),
        injector.get(CtaButtonSignalService),
        injector.get(BulkPaymentService),
      );
    },
  ],
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_PERSONAL,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_BUSINESS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.CHECK_SMS_AUTH_STATUS_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkAuthSmsStatusAndRedirect(
        injector.get(Router),
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
      );
    },
  ],
  [
    ButtonActionType.INIT_INTL_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitInternationPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.TRIP_START_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_END_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleAddEditFeeSubmission(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.CREATE_AD_HOC_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleAdHocFeeSubmission(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleAddEditFeeGroupSubmission(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.UPDATE_CLIENT_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleUpdateClientFeeGroupRequest(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.REDIRECT_BUSINESS_USER_TO_LOGIN,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleBusinessUserRedirectionOnOnboardingFlows(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(KeycloakWrapperService),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
        injector.get(AccountService),
        injector.get(FormSubmissionService),
        injector.get(ConfigService),
      );
    },
  ],
  [
    ButtonActionType.REMOVE_CLIENT_FROM_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleRemoveClientFromFeeGroupRequest(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_UPLOAD_PASS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_FEE_GROUPS_LISTING,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      injector.get(Store).dispatch(new ResetFormDataAction());
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_LEAVING_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const router = injector.get(Router);
      const data = injector
        .get(Store)
        .select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE));
      let isButtonClick = true;
      data.subscribe({
        next: (res) => {
          if (isButtonClick) {
            if (res?.formData?.['entering_country'] !== res?.formData?.['leaving_country']) {
              attri.widgetProperties['urlToNavigate'] = 'multiple-country-notification';
              redirectToPage(router, attri.widgetProperties);
            } else if (
              res?.formData?.['entering_country'] == 'belgium' ||
              res?.formData?.['leaving_country'] == 'belgium'
            ) {
              attri.widgetProperties['urlToNavigate'] = 'upload-boarding-pass';
              redirectToPage(router, attri.widgetProperties);
            } else {
              attri.widgetProperties['urlToNavigate'] = 'exit-point';
              redirectToPage(router, attri.widgetProperties);
            }
          }
          isButtonClick = false;
        },
      });
    },
  ],
  [
    ButtonActionType.MULTIPLE_COUNTRY_NOTI,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const data = injector
        .get(Store)
        .select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE));
      let isButtonClick = true;
      data.subscribe({
        next: (res) => {
          const router = injector.get(Router);
          if (isButtonClick) {
            if (
              res?.formData?.['entering_country'] == 'belgium' ||
              res?.formData?.['leaving_country'] == 'belgium'
            ) {
              attri.widgetProperties['urlToNavigate'] = 'upload-boarding-pass';
              redirectToPage(router, attri.widgetProperties);
            } else {
              attri.widgetProperties['urlToNavigate'] = 'exit-point';
              redirectToPage(router, attri.widgetProperties);
            }
            isButtonClick = false;
          }
        },
      });
    },
  ],

  [
    ButtonActionType.TRIP_EXIT_POINT_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const router = injector.get(Router);
      redirectToPage(router, attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.REMOVE_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      removeFeeGroupAction(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.REMOVE_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      removeFeeAction(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FeeManagementService),
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_VERIFICATION_FLOW,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitVerificationProfileChanges(
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(FormSubmissionService),
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_PROFILE_ZONE,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitProfileZoneUserProfileChanges(
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(FormSubmissionService),
      );
    },
  ],
  [
    ButtonActionType.ADD_TRANSACTION_STEP_1,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      saveTransactionAction(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      );
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_AUTHENTICATION_LINK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      proceedBulkPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_CANCEL,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      cancelAllPayment(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.VERIFICATION_EDIT_INFORMATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.ADD_INTERNATIONAL_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      injector.get(CtaButtonSignalService).setSignal({
        ['add_international_beneficiary']: false,
      });
      callIntlBeneficiaryAPI(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        attri,
        injector.get(AccountService),
        injector.get(FormSubmissionService),
        injector.get(CtaButtonSignalService),
      );
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      approveBulkPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(Actions),
      );
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      rejectAllPayment(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(BulkPaymentService),
      );
    },
  ],
  [
    ButtonActionType.ADD_REVENIR_NEW_TRIP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addRevenirTrip(attri.widgetProperties, injector.get(Store), injector.get(Router));
    },
  ],
  [
    ButtonActionType.CONFIRM_ARCHIVE_TRIP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      archiveRevenirTrip(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(RevenirService),
      );
    },
  ],
  [
    ButtonActionType.RETRY_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      retryFailedFee(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(FeeManagementService),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.BARCODE_CONFIRM_CTA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.BARCODE_CONFIRM_SCAN_ARCHIVE_CTA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      confirmScanArchiveRevenirTrip(attri.widgetProperties, injector.get(Store), injector.get(Router));
    },
  ],
  [
    ButtonActionType.DOWNLOAD_TAX_FREE_FORMS_CTA,
    () => {
      downloadTaxFreeForms();
    },
  ],
  [
    ButtonActionType.REVERT_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      revertChargedFee(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(FeeManagementService),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.SECURE_CVV_DETAILS,
    (injector: Injector) => {
      shareCvvDetails(injector.get(CardManagementService));
    },
  ],
  [
    ButtonActionType.REVENIR_UPLOAD_REVIEWED_TRANSACTION_DOCUMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      uploadReviewedTransactionDocument(attri.widgetProperties, injector.get(Store), injector.get(Router));
    },
  ],
  [
    ButtonActionType.REVENIR_TRIP_BARCODE_SCAN_CHECK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkBarcodeScanDoneRevenirTrip(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.REVENIR_TRIP_BARCODE_CONFIRMATION_DECLARATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.REVENIR_PREVIEW_BOARDING_PASS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      reviewBoardingPass(attri.widgetProperties, injector.get(Router), injector.get(Store));
    },
  ],
  [
    ButtonActionType.SKIP_BOARDING_PASS_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      skipBoardingPass(attri.widgetProperties, injector.get(Router), injector.get(Store));
    },
  ],
  [
    ButtonActionType.SKIP_PASSPORT_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      passportDetails(attri.widgetProperties, injector.get(Store), injector.get(Router));
    },
  ],
  [
    ButtonActionType.PASSPORT_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      passportDetails(attri.widgetProperties, injector.get(Store), injector.get(Router));
    },
  ],
  [
    ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_EDIT_PROFILE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const store = injector.get(Store);
      const router = injector.get(Router);
      store.dispatch(new ResetFormDataAction());
      redirectToPage(router, attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.ADD_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEditBeneficiary(
        injector.get(Store),
        injector.get(Router),
        injector.get(AccountService),
        injector.get(ActivatedRoute),
        injector.get(AlertHandlerService),
        injector.get(BackOverrideService),
      );
    },
  ],
  [
    ButtonActionType.USE_SUGGESTED_NAME_COP,
    (injector: Injector) => {
      updateBeneficiaryFormData(
        injector.get(Store),
        injector.get(Router),
        injector.get(AccountService),
        injector.get(ActivatedRoute),
        injector.get(AlertHandlerService),
        injector.get(BackOverrideService),
      );
    },
  ],
  [
    ButtonActionType.CANCEL_CARD,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      onCancelCard(injector.get(Store), injector.get(AlertHandlerService), injector.get(Router));
    },
  ],
  [
    ButtonActionType.SET_CARD_NAME,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      onSetCardName(injector.get(Store), injector.get(Router), injector.get(WorkflowFormsService));
    },
  ],
  [
    ButtonActionType.SHOW_PIN,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      showEPINUtil(injector.get(Router));
    },
  ],
  [
    ButtonActionType.REFRESH_CARDS_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      refreshCardsAndRedirect(injector, attri);
    },
  ],
]);

export function redirectToPage(router: Router, widgetProperties: BaseWidgetProperties) {
  router.navigateByUrl(
    `/zones/${formatNameAsUrl(widgetProperties['zoneToNavigate'])}/${widgetProperties['urlToNavigate']}`,
  );
}

export const ctaButtonValidationMap: Map<
  ButtonActionType,
  (
    store: Store,
    ctaButtonSignalService: CtaButtonSignalService,
    attri?: UiZoneWidgetAttributeConfig,
    configService?: ConfigService,
  ) => Observable<any>
> = new Map<ButtonActionType, any>([
  [
    ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(BulkPaymentListState.getBulkPaymentList);

      return data.subscribe({
        next: (res) => {
          const totalErrorPayments = res?.meta?.bulkPaymentsSummary?.totalErrors;
          const totalItems = res?.meta?.bulkPaymentsSummary?.totalPayments;
          const totalPendingPayments = res?.meta?.bulkPaymentsSummary?.totalPendingPayments;
          let isDisableButton = false;
          if (totalItems && !totalErrorPayments && !totalPendingPayments) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(BulkPaymentListState.getBulkPaymentList);

      return data.subscribe({
        next: (res) => {
          const totalErrorPayments = res?.meta?.bulkPaymentsSummary?.totalErrors;
          const totalPendingBeneficiary = res?.meta?.bulkPaymentsSummary?.totalPendingBeneficiary;
          let isDisableButton = false;
          if (!totalErrorPayments && !totalPendingBeneficiary) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_START_CONTINUE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(FormActionState.getFormActionStateWithId(attri?.['formKey']));

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: res?.formData?.['startDate'] && res?.formData?.['endDate'] ? true : false,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_END_CONTINUE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(FormActionState.getFormActionStateWithId(attri?.['formKey']));

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: res?.formData?.['startDate'] && res?.formData?.['endDate'] ? true : false,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !!res?.formData?.['entering_country'],
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_UPLOAD_PASS,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !!res?.formData?.['trip-boarding-destination'],
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_LEAVING_COUNTRY_CONTINUE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !!res?.formData?.['leaving_country'],
          });
        },
      });
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS));
      return data.subscribe({
        next: (res) => {
          let isDisableButton = false;
          if (res?.formData?.myFiles?.length) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_AUTHENTICATION_LINK,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(BulkPaymentListState.getBulkPaymentList);
      return data.subscribe({
        next: (res) => {
          const masterBulkPaymentStatus = res?.meta?.bulkPaymentsSummary?.masterStatus;
          let isDisableButton = false;
          if (
            [BulkPaymentMasterStatus.PENDING].includes(
              masterBulkPaymentStatus as unknown as BulkPaymentMasterStatus,
            )
          ) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_CANCEL,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const bulkPaymentData = store.select(BulkPaymentListState.getBulkPaymentList);
      return bulkPaymentData.subscribe({
        next: (res) => {
          const masterBulkPaymentStatus = res?.meta?.bulkPaymentsSummary?.masterStatus;
          let isDisableButton = false;
          if (
            BulkPaymentMasterStatus.PENDING == (masterBulkPaymentStatus as unknown as BulkPaymentMasterStatus)
          ) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],

  [
    ButtonActionType.VERIFICATION_EDIT_INFORMATION,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(ProfileState.getProfile);

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: res?.kycVerificationStatus !== KycStatus.NOT_STARTED ? false : true,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.CREATE_AD_HOC_FEE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      subscribeToAddEditFeeFormStateDataToHandleBtnValidation(store, ctaButtonSignalService, attri, 'ad-hoc');
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      subscribeToAddEditFeeFormStateDataToHandleBtnValidation(store, ctaButtonSignalService, attri);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproverRejectButton(store, ctaButtonSignalService, attri, configService);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproverRejectButton(store, ctaButtonSignalService, attri, configService);
    },
  ],
  [
    ButtonActionType.TRIP_EXIT_POINT_CONTINUE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !(
              !res?.formData?.['approxTime'] ||
              !res?.formData?.['departureInput'] ||
              !res?.formData?.['modeOfTravel']
            ),
          });
        },
      });
    },
  ],
  [
    ButtonActionType.REVERT_FEE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri: UiZoneWidgetAttributeConfig) => {
      const data = store.select(FeeManagementState.getFeeManagement);

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          const isItemsSelected = !!res?.selectedFeeIds?.length ?? false;
          ctaButtonSignalService.setSignal({
            [key]: isItemsSelected,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.REVENIR_TRIP_BARCODE_CONFIRMATION_DECLARATION,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri?: UiZoneWidgetAttributeConfig) => {
      const data = store.select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.CUSTOM_QUESTION));
      return data.subscribe({
        next: (formActionCustomData) => {
          const beneficiaryAddInList =
            !!formActionCustomData?.formData?.['barcode-scanning-declaration-confirmation'];
          let isDisableButton = false;
          if (beneficiaryAddInList) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.REVENIR_TRIP_BARCODE_SCAN_CHECK,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri: UiZoneWidgetAttributeConfig) => {
      handleBarcodeRedirection(store, ctaButtonSignalService, attri);
    },
  ],
]);

export const ctaButtonShowHideMap: Map<
  ButtonActionType,
  (
    store: Store,
    ctaButtonSignalService: CtaButtonSignalService,
    attri?: UiZoneWidgetAttributeConfig,
    configService?: ConfigService,
  ) => Observable<any>
> = new Map<ButtonActionType, any>([
  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproveRejectButtonStatus(store, ctaButtonSignalService, attri, configService);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproveRejectButtonStatus(store, ctaButtonSignalService, attri, configService);
    },
  ],
  [
    ButtonActionType.RETRY_FEE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri: UiZoneWidgetAttributeConfig) => {
      const retryFee =
        store.selectSnapshot(ProjectSettingsState.getProjectSettings)?.feeManagement.retryFailedFeeEnabled ??
        true;
      const key = attri?.['buttonActionType'] as string;
      ctaButtonSignalService.setHideButtonSignal({
        [key]: !retryFee,
      });
    },
  ],
  [
    ButtonActionType.REVERT_FEE,
    (store: Store, ctaButtonSignalService: CtaButtonSignalService, attri: UiZoneWidgetAttributeConfig) => {
      const revertFee =
        store.selectSnapshot(ProjectSettingsState.getProjectSettings)?.feeManagement.feeReversalEnabled ??
        true;
      const key = attri?.['buttonActionType'] as string;
      ctaButtonSignalService.setHideButtonSignal({
        [key]: !revertFee,
      });
    },
  ],
]);
